
// Libs
import { Util } from "@/helpers"
import "firebase/functions"
import "firebase/firestore"
import UserListDialog from "@/components/UserListDialog.vue"
import UserListDialogMulti from "@/components/UserListDialogMulti.vue"
import { isEqual } from "lodash"
import omitBy from "lodash/omitBy"

export default {
  props: ["task", "editMode"],
  components: {
    "user-list-dialog": UserListDialog,
    "user-list-dialog-multi": UserListDialogMulti,
  },
  data() {
    return {
      taskDetailsConfigs: [],
      originalTaskDetails: {},
      updatedTaskDetails: {},
      userModalField: null,
      multiUserModalField: null,
      showUserModal: false,
      showMultiUserModal: false,
    }
  },
  mounted() {
    const siteKeyData = this.$store.state.firetableModule.siteKey
    this.taskDetailsConfigs = Util.getEditableDynamicDetailConfigs(
      siteKeyData,
      null,
      this.task,
      null
    )

    for (const i in this.taskDetailsConfigs) {
      const config = this.taskDetailsConfigs[i]
      if (config.key in this.task.taskSpecificDetails) {
        if (Array.isArray(this.task.taskSpecificDetails[config.key])) {
          this.updatedTaskDetails[config.key] =
            this.task.taskSpecificDetails[config.key].slice()
        } else {
          this.updatedTaskDetails[config.key] =
            this.task.taskSpecificDetails[config.key]
        }
      } else {
        if (Array.isArray(config.defaultValue)) {
          this.updatedTaskDetails[config.key] = config.defaultValue.slice()
        } else {
          this.updatedTaskDetails[config.key] = config.defaultValue
        }
      }
    }
    this.originalTaskDetails = { ...this.updatedTaskDetails }
  },
  methods: {
    stopInputValueChange(event) {
      // Check if the input type is number
      if (event.target.type === "number") {
        // Only stop the number input from changing its value
        event.target.blur() // Temporarily remove focus from the input to prevent value change
        window.setTimeout(() => event.target.focus(), 0) // Restore focus immediately after scrolling
      }
    },
    updateTaskSpecificDetails() {
      const original = { ...this.originalTaskDetails }
      const diffData = omitBy(this.updatedTaskDetails, function (val, key) {
        return isEqual(val, original[key])
      })
      this.$emit("change", diffData)
    },
    hideField(config): boolean {
      let hideThisField = false

      if (config.editable === false) {
        hideThisField = true
      }

      if (this.editMode) {
        hideThisField = false
      } else {
        if (config.onTaskStatus.includes(this.task.taskStatus)) {
          hideThisField = false
        } else {
          hideThisField = true
        }
      }

      return hideThisField
    },
    buildSelectOptions(options: { [key: string]: string }) {
      const items = []
      for (const key in options) items.push({ text: options[key], value: key })
      return items
    },
    barrierCommentsRequired() {
      return Util.barrierCommentsRequired({
        currentValues: this.updatedTaskDetails,
        templateValues: this.taskDetailsConfigs,
      })
    },
    checkIfRequired(fieldName) {
      return this.taskDetailsConfigs[fieldName]?.required
    },
    setUserSelection(user) {
      this.updatedTaskDetails[this.userModalField] = user.id
      this.userModalField = null
      this.setShowUserModal(false, null)
      this.updateTaskSpecificDetails()
    },
    setShowUserModal(value, fieldName) {
      this.showUserModal = value
      this.userModalField = fieldName
    },
    setMultiUserSelection(uids) {
      this.updatedTaskDetails[this.multiUserModalField] = uids.slice()
      this.multiUserModalField = null
      this.setShowMultiUserModal(false, null)
      this.updateTaskSpecificDetails()
    },
    setShowMultiUserModal(value, fieldName) {
      this.showMultiUserModal = value
      this.multiUserModalField = fieldName
    },
    getUserDisplayName(uid) {
      const { siteKeyUsers } = this.$store.state.firetableModule
      return siteKeyUsers?.find((_user) => _user.id === uid)?.displayName ?? ""
    },
    getMultipleUsersDisplayNames(uidArray) {
      const displayNames = []
      const { siteKeyUsers } = this.$store.state.firetableModule
      for (const i in uidArray) {
        const uid = uidArray[i]
        const displayName = siteKeyUsers?.find(
          (_user) => _user.id === uid
        )?.displayName
        if (displayName) {
          displayNames.push(displayName)
        }
      }
      return displayNames.join(", ")
    },
  },
}
